/* eslint-disable react/display-name */
/* eslint-disable react/destructuring-assignment */
import isEmpty from 'lodash/isEmpty';
import moment from 'moment';

export const required = (value) => {
  if (typeof value === 'string') {
    return isEmpty(value.trim()) && 'Заполните обязательное поле';
  }
  return isEmpty(value) && 'Заполните обязательное поле';
};

export const validatePhoneNumber = (value) =>
  value && (!value.match(/\W+(7|8)\s\((\d{3})\)\s\d{3}-\d{2}-\d{2}/g) && !value.match(/^\d{11}/))
  && 'Неверный формат номера, используйте +7 (987) 123-45-67';

export const validatePlaneMachineTime = (value, values) => {
  if (values.plane_machine_start_time && values.plane_machine_end_time) {
    const startTime = values.plane_machine_start_time;
    const endTime = values.plane_machine_end_time;
    if (moment(endTime).isBefore(startTime)) {
      return 'Начальное время должно быть меньше конечного.';
    }
  }
  return '';
};

export const composeValidators = (...validators) => (value) =>
  validators.reduce((error, validator) => error || validator(value), undefined);

export const validateNumber = (value) =>
  value && !value.match(/[0-9]/g)
  && 'Введите пожалуйста цифры';

export const validateAmount = (value) =>
  value && Number(value) < 0 && 'Введите корректную сумму.';

export const validateAmountLength = (value) =>
  value && value.length > 8 && 'Максимум 8 цифр';

export const validateDocumentConfirmation = (value) => !value && 'Документы еще не подтверждены';

export const validateAddress = (value) =>
  value.error && value.error;

export const validatePositiveNumber = (value) => Number(value) <= 0 && 'Число должно быть больше 0';

export const validatByCompanyType = (field) => (value, values) => {
  if (values[field]?.company_type !== 15) {
    return required(value);
  }
  return null;
};
